@font-face {
  font-family: 'league_gothicregular';
  src: url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.eot");
  src: url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.svg#league_gothicregular") format("svg"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.woff") format("woff"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.ttf") format("truetype");
  font-display: block;
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'Source Sans Pro Bold';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.eot");
  src: local("SourceSansPro"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.svg#SourceSansPro-Bold") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.eot");
  src: local("SourceSansPro-Regular"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.svg#SourceSansPro-Regular") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Source Sans Pro Italic';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.eot");
  src: local("SourceSansPro-It"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.svg#SourceSansPro-It") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Source Sans Pro Semibold';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.eot");
  src: local("SourceSansPro-Semibold"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.svg#SourceSansPro-Semibold") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'league_gothicregular';
  src: url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.eot");
  src: url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.svg#league_gothicregular") format("svg"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.woff") format("woff"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.ttf") format("truetype");
  font-display: block;
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'Source Sans Pro Bold';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.eot");
  src: local("SourceSansPro"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.svg#SourceSansPro-Bold") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.eot");
  src: local("SourceSansPro-Regular"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.svg#SourceSansPro-Regular") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Source Sans Pro Italic';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.eot");
  src: local("SourceSansPro-It"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.svg#SourceSansPro-It") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Source Sans Pro Semibold';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.eot");
  src: local("SourceSansPro-Semibold"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.svg#SourceSansPro-Semibold") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 700; }

/** Articles pages sidebar **/
#sidebar h3 {
  display: none; }

#sidebar .nav-list a {
  font-size: 16px;
  font-weight: normal;
  line-height: 2.5;
  letter-spacing: normal;
  color: #393a3b; }
  #sidebar .nav-list a:hover {
    color: #00adee; }

#sidebar .nav-list .active a, #sidebar .nav-list .active a:hover, #sidebar .nav-list .active a:focus {
  color: #00adee;
  font-weight: bold; }
  #sidebar .nav-list .active a .icon-arrow, #sidebar .nav-list .active a:hover .icon-arrow, #sidebar .nav-list .active a:focus .icon-arrow {
    display: none; }

/** Question list body and article page **/
.contentWrapper {
  border: none;
  background: none;
  border-left: 2px solid #f7f7f7;
  /** TODO: remove on mobile **/ }

#categoryHead h1, #fullArticle h1 {
  font-family: "league_gothicregular";
  font-size: 40px;
  letter-spacing: -1.3px; }

#categoryHead .sort {
  display: none; }

.articleList li {
  padding-left: 0; }
  .articleList li a {
    font-family: "Source Sans Pro";
    font-weight: normal;
    font-size: 16px;
    line-height: 2;
    color: #00adee;
    margin-left: 0; }

.articleList .icon-article-doc, .icon-contact, .icon-print {
  display: none; }

#fullArticle, #fullArticle p, #fullArticle ul, #fullArticle ol, #fullArticle li, #fullArticle div, #fullArticle blockquote, #fullArticle dd, #fullArticle table {
  font-family: "Source Sans Pro";
  font-size: 16px; }

.help, .articleFoot p, .articleFoot time {
  font-family: "Source Sans Pro Italic";
  font-size: 14px;
  font-weight: 600;
  line-height: 1.71;
  color: #8e8e8e;
  margin: 0;
  margin-top: 2px; }

#sbContact, #sbContactMobile {
  color: #00adee;
  border: none; }

@font-face {
  font-family: 'league_gothicregular';
  src: url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.eot");
  src: url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.svg#league_gothicregular") format("svg"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.woff") format("woff"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.ttf") format("truetype");
  font-display: block;
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'Source Sans Pro Bold';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.eot");
  src: local("SourceSansPro"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.svg#SourceSansPro-Bold") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.eot");
  src: local("SourceSansPro-Regular"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.svg#SourceSansPro-Regular") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Source Sans Pro Italic';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.eot");
  src: local("SourceSansPro-It"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.svg#SourceSansPro-It") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Source Sans Pro Semibold';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.eot");
  src: local("SourceSansPro-Semibold"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.svg#SourceSansPro-Semibold") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 700; }

/** Choose a Category label **/
.category-list:before {
  content: "Choose a Category:";
  position: absolute;
  top: -27px;
  left: 5px;
  color: black;
  font-family: "Source Sans Pro Semibold";
  font-size: 18px;
  font-weight: 600;
  letter-spacing: -0.4px; }

/** Category Cards **/
.container-fluid {
  max-width: unset; }

.category-list {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  margin: 0; }

.category-list .category, .category-list .category:hover {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background-position-x: center;
  background-position-y: 21px;
  background-repeat: no-repeat;
  background-size: 54% 54%;
  position: relative;
  box-sizing: border-box;
  width: calc(25% - 2 * 5px);
  margin: 5px;
  height: 295px;
  border-radius: 8px;
  padding: 10px;
  border: solid 1px #ededed;
  background-color: white;
  text-align: center;
  box-shadow: 7px 7px 6px 0 #f6f6f6; }
  .category-list .category .article-count, .category-list .category:hover .article-count {
    text-transform: capitalize;
    font-family: "Source Sans Pro Bold";
    font-size: 14px; }

.category-list .category:hover {
  background-color: white;
  box-shadow: 7px 7px 6px 0 #cecece; }
  .category-list .category:hover .article-count:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-position-x: center;
    background-position-y: -100%;
    background-repeat: no-repeat;
    background-size: 90% 90%; }

.category-list .category h3 {
  font-family: "Source Sans Pro Bold";
  font-size: 25px;
  font-weight: bold;
  letter-spacing: -0.8px;
  color: #393a3b; }

#category-4 {
  background-image: url(/rmp-production/helpscout/images/student-avatar.svg); }
  #category-4:hover .article-count:before {
    background-image: url(/rmp-production/helpscout/images/student-hover-shapes.svg); }

#category-6 {
  background-image: url(/rmp-production/helpscout/images/prof-avatar.svg); }
  #category-6:hover .article-count:before {
    background-image: url(/rmp-production/helpscout/images/prof-hover-shapes.svg); }

#category-21 {
  background-image: url(/rmp-production/helpscout/images/account-avatar.svg); }
  #category-21:hover .article-count:before {
    background-position-y: center;
    background-image: url(/rmp-production/helpscout/images/account-hover-shapes.svg); }

#category-19 {
  background-image: url(/rmp-production/helpscout/images/legal-avatar.svg); }
  #category-19:hover .article-count:before {
    background-image: url(/rmp-production/helpscout/images/legal-hover-shapes.svg); }

@media (min-width: 769px) and (max-width: 1140px) {
  .category-list .category, .category-list .category:hover {
    width: calc(50% - 2 * 5px); } }

@media (max-width: 768px) {
  .category-list .category, .category-list .category:hover {
    width: calc(100% - 2 * 5px); } }

@media screen and (min-width: 768px) {
  footer:not(.center) {
    margin-left: calc(25.64% + 48px + 28px);
    margin-top: -40px; }
    footer:not(.center) p {
      text-align: left; } }

/** frequently asked **/
.most-pop-articles {
  width: 100%;
  max-width: unset;
  margin-left: 5px; }
  .most-pop-articles:before {
    margin-top: 48px;
    margin-bottom: 25px;
    content: 'Frequently asked questions:';
    display: inline-block;
    font-family: "Source Sans Pro Semibold";
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -0.4px;
    color: black; }
  .most-pop-articles ul
li a {
    font-family: "Source Sans Pro Bold";
    font-size: 16px;
    font-weight: bold;
    line-height: 2; }
  .most-pop-articles h2, .most-pop-articles .icon-article-doc {
    display: none; }
  .most-pop-articles .icon-article-doc {
    display: none; }
  .most-pop-articles.two-col .popArticles {
    width: 100%;
    float: none; }
    .most-pop-articles.two-col .popArticles a {
      padding-left: 0; }
  .most-pop-articles.two-col > ul:nth-child(3) {
    display: none; }

@media (max-width: 768px) {
  .most-pop-articles.two-col .popArticles li {
    margin-left: 0; } }

/** Reorder things **/
#contentArea {
  display: flex;
  flex-direction: column;
  padding: 0 34px; }

.most-pop-articles {
  order: 1; }

footer {
  order: 2; }

hr.sep {
  display: none; }

@media (max-width: 768px) {
  #contentArea {
    padding: 0 0; } }

/** remove contact button from nav **/
#contact, .nav-collapse, .navbar .btn-navbar {
  display: none; }

@media (max-width: 768px) {
  .navbar .btn-navbar {
    display: none; }
  .navbar a.brand {
    float: none;
    display: inline-block; }
  .navbar .navbar-inner .container-fluid {
    text-align: center; } }

@font-face {
  font-family: 'league_gothicregular';
  src: url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.eot");
  src: url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.svg#league_gothicregular") format("svg"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.woff") format("woff"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.ttf") format("truetype");
  font-display: block;
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'Source Sans Pro Bold';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.eot");
  src: local("SourceSansPro"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.svg#SourceSansPro-Bold") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.eot");
  src: local("SourceSansPro-Regular"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.svg#SourceSansPro-Regular") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Source Sans Pro Italic';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.eot");
  src: local("SourceSansPro-It"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.svg#SourceSansPro-It") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Source Sans Pro Semibold';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.eot");
  src: local("SourceSansPro-Semibold"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.svg#SourceSansPro-Semibold") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 700; }

#sidebar {
  /** Autocomplete **/ }
  #sidebar #searchBar input.search-query {
    width: 100%;
    max-width: unset;
    padding-left: 50px; }
  #sidebar #searchBar:after {
    bottom: 17px;
    left: 18px; }
  #sidebar #searchBar #serp-dd {
    width: calc(100% - (2 * 28px));
    max-width: unset;
    top: calc(100% - 1px);
    left: 28px; }
  #sidebar form .search-query:focus, #sidebar form .search-query:focus:invalid {
    border-color: #ededed;
    box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.05); }

@font-face {
  font-family: 'league_gothicregular';
  src: url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.eot");
  src: url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.svg#league_gothicregular") format("svg"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.woff") format("woff"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.ttf") format("truetype");
  font-display: block;
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'Source Sans Pro Bold';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.eot");
  src: local("SourceSansPro"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.svg#SourceSansPro-Bold") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.eot");
  src: local("SourceSansPro-Regular"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.svg#SourceSansPro-Regular") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Source Sans Pro Italic';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.eot");
  src: local("SourceSansPro-It"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.svg#SourceSansPro-It") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Source Sans Pro Semibold';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.eot");
  src: local("SourceSansPro-Semibold"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.svg#SourceSansPro-Semibold") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 700; }

/** Search Bar **/
#searchBar button[type="submit"] {
  display: none; }

#searchBar input.search-query {
  font-family: "Source Sans Pro";
  font-size: 18px;
  width: 488px;
  max-width: 90vw;
  height: 56px;
  border-radius: 28px;
  box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.05);
  border: solid 1px #ededed;
  background-color: white;
  margin-top: 30px;
  padding-left: 102px;
  /** for search icon **/ }

#searchBar:after {
  content: '';
  background-image: url("/rmp-production/helpscout/images/search.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 22px;
  height: 22px;
  position: absolute;
  display: block;
  bottom: 42px;
  left: calc(((100% - 488px) / 2) + 63px); }

#searchBar input.search-query:placeholder {
  font-family: "Source Sans Pro";
  font-size: 18px;
  color: #bbbbbb; }

/** Autocomplete **/
#searchBar #serp-dd {
  width: 432px;
  max-width: calc(90vw - (2 * 28px));
  top: calc(100% - 2em - 1px);
  left: calc(((100% - 488px) / 2) + 28px);
  border: 1px solid #ededed;
  border-top: none;
  border-radius: 0;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  box-shadow: 7px 7px 6px 0 #cecece; }
  #searchBar #serp-dd .result > li a, #searchBar #serp-dd .noResults {
    font-family: "Source Sans Pro";
    font-size: 16px;
    font-weight: bold; }
  #searchBar #serp-dd .result > li a {
    color: #00adee; }

@media (max-width: 768px) {
  #searchBar {
    padding: 2em 0; }
  #searchBar input.search-query {
    padding-left: 47px; }
  #searchBar:after {
    left: calc(((100% - 488px) / 2) + 20px); } }

@media (max-width: 542.22222px) {
  #searchBar:after {
    left: calc(((100% - 90vw) / 2) + 20px); }
  #searchBar #serp-dd {
    left: calc(((100% - 90vw) / 2) + 28px); } }

@font-face {
  font-family: 'league_gothicregular';
  src: url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.eot");
  src: url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.svg#league_gothicregular") format("svg"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.woff") format("woff"), url("/rmp-production/helpscout/fonts/leaguegothic-regular-webfont.ttf") format("truetype");
  font-display: block;
  font-style: normal;
  font-weight: normal; }

@font-face {
  font-family: 'Source Sans Pro Bold';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.eot");
  src: local("SourceSansPro"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Bold.svg#SourceSansPro-Bold") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 700; }

@font-face {
  font-family: 'Source Sans Pro';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.eot");
  src: local("SourceSansPro-Regular"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Regular.svg#SourceSansPro-Regular") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Source Sans Pro Italic';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.eot");
  src: local("SourceSansPro-It"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-It.svg#SourceSansPro-It") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 400; }

@font-face {
  font-family: 'Source Sans Pro Semibold';
  src: url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.eot");
  src: local("SourceSansPro-Semibold"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.eot?#iefix") format("embedded-opentype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.woff2") format("woff2"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.woff") format("woff"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.ttf") format("truetype"), url("/rmp-production/helpscout/fonts/subset-SourceSansPro-Semibold.svg#SourceSansPro-Semibold") format("svg");
  font-display: block;
  font-style: normal;
  font-weight: 700; }

/** Search prompt text **/
#docsSearch h1 {
  display: none; }

#docsSearch {
  text-align: center;
  background: white;
  border: none; }

#docsSearch form:before {
  color: black;
  content: "How Can We Help?";
  display: inline-block;
  font-family: "league_gothicregular";
  font-size: 64px;
  letter-spacing: -2px;
  line-height: normal; }

@media (max-width: 768px) {
  #docsSearch #searchBar {
    text-align: center; } }

a {
  font-weight: bold;
  color: #00adee; }

#main-content {
  background: none; }
